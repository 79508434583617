import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layout';
import SignInMain from '../components/SignInMain';
import { AuthUserContext } from '../components/Session';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_iwase.jpg';
import tobori from '../images/ob/takeda-face.jpg';
import toboriImg1 from '../images/ob/takedaImg1.jpg';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>

class ManabePage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>OBインタビュー　竹田謙|國學院大學久我山高等学校　バスケットボール部</title>
        <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 岩瀬宥太</h4>
          <div className="mng-text">
こんにちは！本日インタビュアーをさせていただく、2年マネージャーの岩瀬です。今日はよろしくお願いします！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <h4 className="vertical-timeline-element-subtitle">48期（1997年卒） 竹田謙</h4>
          <div className="ob-text">
            こんにちは！こちらこそよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          それではさっそく、竹田さんの自己紹介からよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          今は横浜ビー・コルセアーズというB.LEAGUEのチームで選手をしています。久我山は1997年の卒業で、酒井先生の2つ上の学年でした。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。では最初の質問です。なぜ、いつからBリーガーを目指したのですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          僕がプロになった時はB.LEAGUEはまだ無かったんだよね。僕は大学を卒業して最初の1年は、サラリーマンをやりながら今で言うB2のリーグに所属していました。その時に大学の同級生達がトップのリーグで活躍しているのを見て、会社を辞めてバスケット一本で生活することを決めました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。それでは次の質問です。プロになるために誰よりも努力したと思うことは何ですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          僕はあんまり、自分が周りの選手より優れてると思ったことが無くて、周りの選手についていくことが多かったですね。ポジション的にマッチアップする選手にシューターや得点を取る選手が多かったので、ウエイトなどは結構やってディフェンスにも力を入れてましたね。そこは誰よりも努力したと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。プロになっても派手なプレーではなくて、地道にディフェンスを頑張ることが大事なんですね！それでは次に、プロになったときの心境を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          当然嬉しい気持ちはありましたし、バスケット一本で生活するっていうのにワクワクしました。プロになって1日目の練習が始まれば、環境に慣れたり、コーチに言われたことはできるようにするっていうのは必死にやっていましたね。プロになって嬉しい気持ちもあったけど、始まってみれば必死についていかないとっていう感じでした。
        </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obpageImg" alt="manabekei" src={toboriImg1} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。やはりプロの世界って厳しいんですね！毎日切磋琢磨されていると思いますが、一方でオフの日はどのように過ごされているんですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          現在は、奥さんと子供がいるので家族で過ごすことが多いんだけど、たまに他の選手と海に行ったり、バーベキューしに行くこともあります。基本的にはあんまり選手同士でっていうのはなくて、みんなそれぞれ家庭があるので、家族で過ごしてますね。でも、若い時は同じ年齢の選手と遊ぶことも多かったですね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。試合のあった日はチームで飲みに行ったりするんですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          チームによって頻度は違うと思うけど、決起会やお疲れ様でした会みたいなのはやるよ。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          そういう時に、竹田さんはムードメーカーになったりするんですか？（笑）
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そういうキャラじゃないんだけど、酔っぱらってる時はカラオケで若い子が歌う様な曲を歌ったりするかな。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          どんな曲を歌われているのか気になります！(笑)ありがとうございました。またバスケットボールの話になるのですが、竹田さんの強みや得意なプレーについて教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          これっていうプレーはないんだけど、自分が30点40点取る選手じゃないから、周りを活かすっていうのは意識してますね。チームのエースだったりがプレーしやすいようにしたり、ディフェンスでは、ディフェンスの苦手な選手をヘルプしたりっていうのは、高校の時から続けていることです。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。そういった周りを助けるプレースタイルは、久我山で確立されたんですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね。特にディフェンスのカバーとかっていうのは手塚先生に教えてもらってましたし、久我山の文化的にそういったディフェンスが確立されたと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。久我山の堅守考攻というスローガンがあるように、当時からディフェンスには力を入れていたんですね。それでは次に、プロとして勝つためにどのような事を心がけていますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          試合前に相手選手の情報を頭に入れておいて、相手に気持ちよくプレーさせないようにっていうのは心がけてますね。それくらいかな。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。事前準備が大切という事ですね！それでは、次に久我山バスケ部での思い出を教えて下さい。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          やっぱり夏合宿のときに、ひたすら走ってたことかな。みんなで芦花公園まで競走して、そこでランメニューをして、また競走で帰ってくるっていうのをやってたね。当時のコーチの桜井さんっていう人が笛を振り回して「よーし走るぞー」って言ってたことが印象に残ってるね。（笑）あとは近くの中華料理屋で、めちゃくちゃな量を朝昼晩でたべさせられたことかな。（笑）
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          それは凄そうですね（笑）まさにラントレ、食トレですね！悔しかったことなどはありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          自分の2つ上の代がウィンターカップ準優勝した代だったから、自分の代の時にいい結果を残すことができなかったことかな。2つ上の代とのギャップを感じたのがすごく悔しかったね。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございました。それでは次に、今後の目標を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          バスケットに関わってきた人間として、やっぱり日本のバスケットが強くなることと、日本のバスケットがいろんな人に広がるような活動をしたいと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          素敵な目標をありがとうございます！それでは最後に選手達に向けてメッセージを貰えると嬉しいです。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          やっぱりまずはバスケットを楽しんでほしいのと、バスケットで自分を表現してほしいことです。例えば、シュートフォーム一つとっても100人いれば100通りあるように、自分が納得する方法で納得する結果を出すということをしてほしいです。これはバスケットに限ったことじゃなくて、これからの人生で取り組んでほしいと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
        ありがとうございます。以上でインタビューは終わりになります。これからも久我山バスケ部を応援よろしくお願いします。本日はありがとうございました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          ありがとうございました。またいつか体育館に顔を出します。
        </div>
        </VerticalTimelineElement>


      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <ManabePage />
  </Layout>
);
